import { FetchResponse } from 'types/FetchResponse';
import { GiantEventData } from 'types/GiantEvents';
import { EventStatus } from 'types/EventStatus';
import { InvoiceRequestParams } from 'types/Invoice';

import { wrapFetch } from 'util/api';
import { OrderInfo } from 'util/order';
import { PaymentRequestParams } from 'util/payment';

import { RideParticipant, OrderDeliveryInfo } from './rideOrder';
import { DeliveryRequestParams } from './ride';
import { ProductSelect } from './product';
import { EventLocation } from './events';

export interface CourseParticipant extends RideParticipant {
	en_first_name: string;
	en_last_name: string;
	weight: number;
	country: string;
	city: string;
	address: string;
	note: string;
	has_participated: boolean;
	is_primary: boolean;
}

type CourseParticipantRequestParams = Pick<
	CourseParticipant,
	| 'first_name'
	| 'last_name'
	| 'en_first_name'
	| 'en_last_name'
	| 'nationality'
	| 'identity_no'
	| 'gender'
	| 'birthday'
	| 'height'
	| 'weight'
	| 'phone'
	| 'email'
	| 'contact_name'
	| 'contact_relationship'
	| 'contact_phone'
	| 'extra_field_1'
	| 'extra_field_2'
	| 'extra_field_3'
	| 'roommate_name'
	| 'eating_habit'
	| 'eating_habit_note'
	| 'medical_record'
	| 'medical_record_note'
	| 'parent_consent'
	| 'elderlies_consent'
	| 'has_participated'
	| 'country'
	| 'city'
	| 'address'
	| 'note'
> & {
	is_primary: boolean;
	room_id: number;
	apply_type_id: number;
	gift_id: number[];
	products: ProductSelect[];
};

export interface CourseOrder extends OrderInfo {
	name: string;
	active_status: EventStatus;
	activity_name: string;
	team_name: string;
	apply_fee: number;
	date: string;
	phone: string;
	cellphone: string;
	email: string;
	identity_no: string;
	extra_field_name_1: string;
	extra_field_name_2: string;
	extra_field_name_3: string;
	delivery_info: OrderDeliveryInfo;
	participants: CourseParticipant[];
	course_id: number;
	country_id: number;
	date_id: number; // 課程講座 上課日期對應活動的唯一 ID
}

export type CourseOrderPreview = Pick<
	CourseOrder,
	'id' | 'number' | 'selector' | 'status' | 'payment_status' | 'activity_name' | 'date' | 'timezone'
> & {
	model: 'CourseOrder';
	type: GiantEventData.eventsBikeClasses;
	is_history: boolean;
	locations: EventLocation[];
};

type FetchCourseOrderBySelectorFunc = (selector: string) => Promise<FetchResponse<CourseOrder>>;

/**
 * 取得課程講座訂單詳細資訊
 */
export const fetchCourseOrderBySelectorFunc: FetchCourseOrderBySelectorFunc = async selector => {
	const response = await wrapFetch(`courseOrder/${selector}`, {
		method: 'GET',
	});

	return response;
};

type UpdateCourseOrderBySelectorFunc = (
	selector: string,
	data: Partial<CourseParticipant>[],
) => Promise<FetchResponse<string>>;

/**
 * 更新聯絡人之課程講座參加者訂單資訊
 */
export const updateCourseOrderBySelectorFunc: UpdateCourseOrderBySelectorFunc = async (
	selector,
	data,
) => {
	const response = await wrapFetch(`courseOrder/${selector}`, {
		method: 'PUT',
		body: JSON.stringify({ participants: data }),
	});

	return response;
};

export type ValidateCourseParticipantRequestParams = {
	course_id?: number;
	date?: string;
	participants: {
		name: string;
		identity_no: string;
		is_primary?: boolean;
	}[];
};

/**
 * 驗證課程活動參加者表單 - 身分證是否重複報名、活動是否存在、是否超過報名名額
 */
export const validationCourseParticipantFunc = async (
	data: ValidateCourseParticipantRequestParams,
) => {
	const response = await wrapFetch(
		'courseOrder/participants/verify',
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
		{},
		{ notThrowError: true },
	);

	return response;
};

export type CourseOrderRequestBody = {
	course_id: number;
	date: string; // 'YYYY-MM-DD'
	total_price: number;
	newebpay_token_id: number;
	team_name: string;
	name: string;
	cellphone: string;
	phone: string;
	email: string;
	identity_no: string;
	participants: Partial<CourseParticipantRequestParams>[];
	code: string;
} & Partial<DeliveryRequestParams> &
	Partial<InvoiceRequestParams> &
	Partial<PaymentRequestParams>;

export type CourseOrderResponse = {
	selector?: string;
	view?: string;
	product_stock_errors?: {
		[id: number]: string;
	};
};

type SubmitCourseOrderFunc = (
	data: Partial<CourseOrderRequestBody>,
) => Promise<FetchResponse<CourseOrderResponse>>;

/**
 * 建立課程講座訂單
 */
export const submitCourseOrderFunc: SubmitCourseOrderFunc = async data => {
	const response = await wrapFetch(
		'courseOrder',
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
		{},
		{ withAuth: true, notThrowError: true },
	);

	return response;
};

type CourseOrderTransactionData = Partial<InvoiceRequestParams> & Partial<PaymentRequestParams>;

export type CourseOrderTransactionResponse = {
	view?: string;
};

type SubmitCourseOrderTransactionFunc = (
	selector: string,
	id: number,
	data: CourseOrderTransactionData,
) => Promise<FetchResponse<CourseOrderTransactionResponse>>;

/**
 * 課程講座訂單 transaction 付款
 */
export const submitCourseOrderTransactionFunc: SubmitCourseOrderTransactionFunc = async (
	selector,
	id,
	data,
) => {
	const response = await wrapFetch(`courseOrder/${selector}/transaction/${id}`, {
		method: 'POST',
		body: JSON.stringify(data),
	});

	return response;
};

type CancelCourseOrderFunc = (selector: string) => Promise<FetchResponse>;

/**
 * 取消課程講座訂單
 */
export const cancelCourseOrderFunc: CancelCourseOrderFunc = async selector => {
	const response = await wrapFetch(`courseOrder/${selector}/cancel`, {
		method: 'PUT',
	});

	return response;
};
