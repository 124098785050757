import { ChangeEvent } from 'react';
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, handleActions, Action } from 'redux-actions';
import moment from 'moment';
import { AnyAction, Dispatch } from 'redux';
import { ActionsObservable, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { GiantEventRoute, GiantEventRouteType } from 'types/GiantEvents';
import { InputFiled } from 'types/InputFiled';
import { GenderValues, genderValueToCodeMap } from 'types/Gender';

import { useRedux } from 'util/hook/redux';
import { t } from 'util/i18n';
import { isExist } from 'util/helper';
import { objectDataKeyMapper } from 'util/functions/objectKeyMapper';

import { calcEventsReservationShoppingDetail } from 'models/eventsReservation';
import { UserInfoData } from 'models/auth';
import { ProductItemWithAmount } from 'models/product/type';

import { relationshipI18nNs } from 'types/Relationship';
import {
	BikeclassesFormDataType,
	BikeClassesFormField,
	EventsParticipantForms,
	EventsParticipantFormType,
	FeaturedridesFormDataType,
	FeaturedRidesFormField,
	PurchaseField,
	StoreRidesFormField,
} from './type';
import { formTemplates } from './utils';
import { GetState, State as GlobalState } from '../reducers';
import { ValidateRideParticipantRequestParams, validationRideParticipantFunc } from 'api/rideOrder';
import { DynamicState } from 'types/DynamicState';
import { FormDataMapping } from 'types/FormDataMapping';
import { openModal } from 'models/modal';
import { validationCourseParticipantFunc } from 'api/courseOrder';

// 測試表單用假資料
/* import {
	eventsParticipantBikeclassesMockByIds,
	eventsParticipantBikeclassesMockIds,
} from './mockData'; */

export interface FormData<T extends EventsParticipantFormType = EventsParticipantFormType> {
	id?: number; // 報名時是用 timestamp， 報名完後端回傳是用 id
	form: EventsParticipantForms[T];
}

export interface State<T extends EventsParticipantFormType = EventsParticipantFormType> {
	event: {
		type: T;
		id: number | null;
	};
	form: {
		ids: number[];
		byIds: { [key: Required<FormData<T>>['id']]: FormData<T>['form'] };
		editing: {
			id: null | number;
			data: null | FormData<T>['form'];
		};
		template: null | FormData<T>['form'];
	};
}

const initialState: State = {
	event: {
		type: 'bikeclasses',
		id: null,
	},
	form: {
		ids: [],
		byIds: {},
		// ids: [...eventsParticipantBikeclassesMockIds],
		// byIds: {
		// 	...eventsParticipantBikeclassesMockByIds,
		// },
		editing: {
			id: null,
			data: null,
		},
		template: null,
	},
};

const clearEventsParticipant = createAction('CLEAR_EVENTS_PARTICIPANT');

export interface FormChangePayload<T extends EventsParticipantFormType> {
	key: {
		featuredrides: FeaturedRidesFormField;
		bikeclasses: BikeClassesFormField;
		storerides: StoreRidesFormField;
	}[T];
	data: Partial<InputFiled>;
}

const changeParticipantForm = createAction(
	'CHANGE_PARTICIPANT_FORM',
	<T extends EventsParticipantFormType>({ key, data }: FormChangePayload<T>) => ({
		key,
		data,
	}),
);

export const changeParticipantFormTemplate = createAction(
	'CHANGE_PARTICIPANT_FORM_TEMPLATE',
	<T extends EventsParticipantFormType>({ key, data }: FormChangePayload<T>) => ({
		key,
		data,
	}),
);

interface FormTypeIdPayload {
	eventType: EventsParticipantFormType;
	eventId?: State['event']['id'];
}

export const setEventsTypeId = createAction<FormTypeIdPayload, FormTypeIdPayload>(
	'SET_EVENTS_PARTICIPANT_TYPE_ID',
	({ eventType, eventId }) => ({ eventType, eventId }),
);

const initEventsParticipantForm = createAction('INIT_EVENTS_PARTICIPANT_FORM');

const initEventsEditingParticipantForm = createAction('INIT_EVENTS_EDITING_PARTICIPANT_FORM');

const removeParticipantForm = createAction<FormData['id'], FormData['id']>(
	'REMOVE_PARTICIPANT_FORM',
	id => id,
);

const setParticipantFormEditable = createAction<number | undefined, number | undefined>(
	'SET_EVENTS_PARTICIPANT_FORM_EDITABLE',
	id => id,
);

const completeParticipantFormEdit = createAction<number>(
	'COMPLETE_EVENTS_PARTICIPANT_FORM_EDIT',
	() => new Date().getTime(),
);

type UpdateParticipantPrimaryStatusPayload = {
	data: {
		[key: Required<FormData<EventsParticipantFormType>>['id']]: FormData<
			EventsParticipantFormType
		>['form'];
	};
};

export const updateParticipantPrimaryStatus = createAction(
	'UPDATE_PARTICIPANT_PRIMARY_STATUS',
	() => (_: Dispatch, getState: GetState) => {
		const {
			eventsParticipant: {
				form: { ids, byIds },
			},
			course: {
				reservationCourseInfo: { data: courseData },
			},
		} = getState();

		const { leftQuota, isOpenWaitingList = false } = courseData || {};

		const data = { ...byIds };
		const isSecondaryIds = ids.slice(leftQuota);

		// 如果候補機制沒有開啟，則不會出現備取的狀況
		if (!isOpenWaitingList) {
			return { data };
		}

		isSecondaryIds.forEach(id => {
			data[id] = {
				...byIds[id],
				isPrimary: { value: false, valid: true, error: '', required: false },
			} as FormData<'bikeclasses'>['form'];
		});

		return { data };
	},
);

/**
 * 操作參加者是否為正取的狀態
 *
 * 1. isParticipantPrimary 只控制「增加」的狀態，故新增此 action 來更新其他情況的狀態
 * 2. 當參加者 index < leftQuota 則所有參賽者的 isPrimary 為 true
 *
 * ref: https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/issues/1148
 */
const setParticipantStatusToPrimary = createAction<
	(dispatch: Dispatch, getState: GetState) => void
>('SET_PARTICIPANT_STATUS_TO_PRIMARY', () => (_, getState) => {
	const {
		eventsParticipant: {
			form: { ids, byIds },
		},
		course: {
			reservationCourseInfo: { data: courseData },
		},
	} = getState();

	// 正取名額人數
	const leftQuota = courseData?.leftQuota || 0;

	/**
	 * 1. 迴圈跑每個參加者的 index (participantIndex)是否小於 正取名額
	 * ps. 使用 參加者的 index 原因在於整個參加者的清單排序，只是按照加入的先後排序，並且就可以處理參加者人數 === leftQuota 的情況
	 *
	 * 2. 取出 ids 的值
	 * 3. 帶入 byIds 並修改 isPrimary 的值
	 */
	for (let participantIndex = 0; participantIndex < leftQuota; participantIndex++) {
		const id = ids[participantIndex]; // 取出 id 的值

		byIds[id] = {
			...byIds[id],
			isPrimary: { value: true, valid: true, error: '', required: false },
		} as FormData<'bikeclasses'>['form'];
	}
});

/**
 * 每當「參加者表單初始化完成」、「編輯完一個參加者表單」或「刪除一個參賽者表單」，重新計算活動課程的訂單明細和總金額。
 */
export const calcEventsRegiterDetailEpic = (action$: ActionsObservable<AnyAction>) =>
	action$.pipe(
		ofType(
			'INIT_EVENTS_PARTICIPANT_FORM',
			'COMPLETE_EVENTS_PARTICIPANT_FORM_EDIT',
			'REMOVE_PARTICIPANT_FORM',
		),
		map(() => calcEventsReservationShoppingDetail()),
	);

/** 同步聯絡人資訊到參加者資訊欄位 */
const syncEventsParticipantFormByContactInfo = createAction<
	(dispatch: Dispatch, getState: GetState) => void
>('SYNC_EVENTS_PARTICIPANT_FORM_BY_CONTACT_INFO', () => (dispatch, getState) => {
	const {
		eventsReservation: { form },
	} = getState();

	if (form.cellphone.value) {
		dispatch(
			changeParticipantForm({
				key: 'phone',
				data: {
					value: form.cellphone.value,
					error: '',
					valid: true,
				},
			}),
		);
	}

	if (form.email.value) {
		dispatch(
			changeParticipantForm({
				key: 'email',
				data: {
					value: form.email.value,
					error: '',
					valid: true,
				},
			}),
		);
	}

	if (form.id.value) {
		dispatch(
			changeParticipantForm({
				key: 'citizenId',
				data: {
					value: form.id.value,
					error: '',
					valid: true,
				},
			}),
		);
	}
});

const KeyMapperFromUserInfoToParticipantForm = {
	nationalityCode: 'citizenship',
	phoneNumber: 'mobile',
	gender: 'sex',
	// contact_name: 'emergencyName',
	// contact_relationship: 'emergencyRelation',
	// contact_phone: 'emergencyPhone',
} as const;

type KeyMapperFromUserInfoToParticipantForm = typeof KeyMapperFromUserInfoToParticipantForm;

/**
 * 同步會員資料到參加者資訊欄位
 */
const syncEventsParticipantFormByUserProfile = createAction<
	(_: Dispatch, getState: GetState) => void,
	boolean
>(
	'SYNC_EVENTS_PARTICIPANT_FORM_BY_USER_PROFILE',
	(check = true) => (dispatch: Dispatch, getState: GetState) => {
		const {
			auth: { currentUserGid, userDataByGid },
			nationalities: {
				data: { byCode: nationalitiesByCodes },
			},
		} = getState();

		const currentUserData = userDataByGid[currentUserGid];

		// 轉換後端格式
		const data = objectDataKeyMapper<UserInfoData, KeyMapperFromUserInfoToParticipantForm>(
			currentUserData,
			KeyMapperFromUserInfoToParticipantForm,
		);

		(Object.keys(data) as (keyof typeof data)[]).forEach(key => {
			const DataValue = check ? data[key] : '';
			let formDataValue: unknown = DataValue;

			if (key === 'sex') {
				formDataValue = {
					label: genderValueToCodeMap[DataValue as GenderValues],
					value: genderValueToCodeMap[DataValue as GenderValues],
				};
			}
			if (key === 'mobile') {
				formDataValue = { intIdNum: '', phoneNum: DataValue };
			}

			// 待修正
			// if (key === 'phone' && typeof DataValue === 'string') {
			// 	console.log('dataValue', typeof DataValue);
			// 	console.log('DataValue?.match(+/)?.[0]', DataValue?.substring(4));
			// 	formDataValue = { intIdNum: currentUserData.nationalityCode || '', phoneNum: DataValue?.substring(4) }
			// }
			if (key === 'citizenship') {
				formDataValue = {
					label: nationalitiesByCodes[DataValue as string]?.name || '',
					value: nationalitiesByCodes[DataValue as string]?.code || '',
				};
			}
			if (key === 'birthday' && check) {
				formDataValue = formDataValue !== '' ? moment(formDataValue as string) : null;
			} // 後端回傳的空值為空字串
			// if (key === 'emergencyRelation') {formDataValue = { label: t(`${relationshipI18nNs}:${DataValue}`), value: DataValue }}
			// if (key === 'emergencyPhone' && typeof DataValue === 'string') {
			// 	formDataValue = {
			// 		intIdNum: currentUserData.contact_phone_country_code || '',
			// 		phoneNum: DataValue?.split(' ')[1] || '',
			// 	};
			// }

			if (key !== undefined) {
				dispatch(
					changeParticipantForm({
						key: key as keyof BikeclassesFormDataType,
						data: { value: formDataValue, valid: true, error: '' },
					}),
				);
			}
		});
	},
);

interface ChangeParticipantAdditionalsPayload {
	key: PurchaseField;
	data: ProductItemWithAmount;
}

const changeParticipantAdditionals = createAction<
	ChangeParticipantAdditionalsPayload,
	ChangeParticipantAdditionalsPayload
>('CHANGE_PARTICIPANT_ADDITIONALS', ({ key, data }) => ({ key, data }));

/**
 * 驗證參加者表單欄位身分證或是護照號碼是否重複
 *
 * return 錯誤訊息以及重複身分證或是護照號碼的參加者姓名
 * */
const changeParticipantFormError = createAction<
	(_: Dispatch, getState: GetState) => Promise<boolean>
>('CHANGE_PARTICIPANT_FORM_ERROR', () => async (dispatch, getState) => {
	try {
		const {
			eventsParticipant: {
				event: { id: eventId, type },
				form: {
					byIds,
					ids,
					editing: { data: editingData, id: editingId },
				},
			},
			routing: {
				queries: { date },
			},
			locale: { currentLocCode },
		} = getState();

		const editingParticipant = {
			name:
				currentLocCode === 'TW'
					? `${editingData?.lastName?.value} ${editingData?.firstName?.value}`
					: `${editingData?.firstName?.value} ${editingData?.lastName?.value}` || '',
			identity_no: editingData?.citizenId?.value || '',
		};

		const participantValues = ids.map(pId => {
			const participant = byIds[pId];
			// Create base participant object
			const participantObj = {
				name:
					currentLocCode === 'TW'
						? `${participant?.lastName?.value} ${participant?.firstName?.value}`
						: `${participant?.firstName?.value} ${participant?.lastName?.value}` || '',
				identity_no: participant?.citizenId?.value || '',
			};

			// Add isPrimary if type is 'bikeclasses'
			return type === 'bikeclasses'
				? { ...participantObj, is_primary: participant?.isPrimary?.value }
				: participantObj;
		});

		// Add isPrimary if type is 'bikeclasses'
		const finalEditingParticipant =
			type === 'bikeclasses'
				? { ...editingParticipant, is_primary: editingData?.isPrimary?.value }
				: editingParticipant;

		// issue-#2007 防止編輯中的參加者表單以及編輯過的參加者表單誤被當成兩個參加者送回後端驗證
		// Check if finalEditingParticipant's identity_no already exists in participantValues
		const isDuplicate = participantValues.some(
			participant => participant.identity_no === finalEditingParticipant.identity_no
		);

		let params; // Declare params variable

		// Construct params based on event type
		if (type === 'bikeclasses') {
			params = {
				course_id: eventId || 0,
				date: moment(date).format('YYYY-MM-DD'),
				participants: isDuplicate ? participantValues : [finalEditingParticipant, ...participantValues],
			};
		} else {
			params = {
				ride_id: eventId || 0,
				participants: isDuplicate ? participantValues : [finalEditingParticipant, ...participantValues],
			};
		}

		// Early return if there's no modification in the editing participant's data
		// 如果有編輯中的參加者資料並且其資料沒有重複，則不進行驗證
		const isUnchanged = editingData?.citizenId?.value && (editingData?.citizenId?.value) in params.participants;

		if (editingId && ids.includes(editingId) && isUnchanged) {
			return true;
		}

		// Choose the appropriate validation function based on the type
		const validationFunc =
			type === 'bikeclasses' ? validationCourseParticipantFunc : validationRideParticipantFunc;

		const { status, message, data } = await validationFunc(params);

		if (status !== 200 && status !== 201) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: 'eventsReservationForm',
					data: {
						message: `${data?.participants[0]} ${t(`eventsReservationForm:${message}`)}`,
						status: 'warning',
					},
				}),
			);

			throw new Error(message);
		}

		return status === 200 || status === 201;
	} catch (error) {
		console.error(error);
		return false;
	}
});

type SetupParticipantFormByRideOrderPayload = {
	id: number;
} & (
	| {
			type: GiantEventRoute.featuredrides;
			form: EventsParticipantForms['featuredrides'];
	  }
	| {
			type: GiantEventRoute.storerides;
			form: EventsParticipantForms['storerides'];
	  }
);

type ProductSelectedPayload = {
	[key: number]: {
		stockId: number;
		amount: number;
		colorId: number;
		specId: number;
	};
};

/** 將 主題活動 或 門市約騎 訂單資訊 map 到參加者表單欄位 */
export const setupParticipantFormByRideOrder = createAction<
	(dispatch: Dispatch, getState: GetState) => SetupParticipantFormByRideOrderPayload,
	number,
	EventsParticipantFormType
>('SETUP_PARTICIPANT_FORM_BY_RIDE_ORDER', (id, type) => (_: Dispatch, getState: GetState) => {
	const {
		nationalities: {
			data: { byCode: nationalitiesByCode },
		},
		rideOrder: {
			detail: { data: selector },
			bySelector,
		},
		eventsParticipant: {
			form: { template },
		},
		ride: {
			reservationRideInfo: {
				data: { additional_products },
			},
		},
	} = getState();

	const registData = bySelector[selector];
	const [participantId] = registData.participants.ids.filter(pId => pId === id);
	const participantData = registData.participants.byIds[participantId as number];

	let templateData = template;

	if (templateData === null) {
		templateData = formTemplates[type];
	}

	// 當訂單當中有贈品時，將贈品資料帶入表單
	const giveawaysData: ProductSelectedPayload = {};
	const ridesGifts = additional_products?.gifts || [];
	ridesGifts.forEach((product: { stocks: { [x: string]: any }; id: number }) => {
		const stockList = Object.keys(product.stocks).map(key => product.stocks[key]);
		participantData.gift.forEach(gift => {
			if (product.id === gift.product_id) {
				giveawaysData[product.id] = {
					stockId: gift.product_stock_id,
					amount: 0,
					colorId: stockList.find(stock => stock.id === gift.product_stock_id)?.color_id,
					specId: stockList.find(stock => stock.id === gift.product_stock_id)?.size_id,
				};
			}
		});
	});

	// 當訂單當中有加價購商品時，將商品資料帶入表單
	const productPurchaseData: ProductSelectedPayload = {};
	const ridesProducts = additional_products?.products || [];
	ridesProducts.forEach((product: { stocks: { [x: string]: any }; id: number }) => {
		const stockList = Object.keys(product.stocks).map(key => product.stocks[key]);
		participantData.products.forEach(prod => {
			if (product.id === prod.product_id) {
				productPurchaseData[product.id] = {
					stockId: prod.product_stock_id,
					amount: 0,
					colorId: stockList.find(stock => stock.id === prod.product_stock_id)?.color_id,
					specId: stockList.find(stock => stock.id === prod.product_stock_id)?.size_id,
				};
			}
		});
	});

	if (type === GiantEventRoute.featuredrides) {
		return {
			id,
			type: GiantEventRoute.featuredrides,
			form: {
				...templateData,
				lastName: {
					...templateData.lastName,
					value: participantData.last_name,
				},
				firstName: {
					...templateData.firstName,
					value: participantData.first_name,
				},
				citizenship: {
					...templateData.citizenship,
					value: {
						label: nationalitiesByCode[participantData.nationality]?.name || '',
						value: participantData.nationality || null,
					},
				},
				citizenId: {
					...templateData.citizenId,
					value: participantData.identity_no,
				},
				sex: {
					...templateData.sex,
					value:
						participantData.gender === 'F'
							? { label: t('gender:F'), value: 'F' }
							: { label: t('gender:M'), value: 'M' },
				},
				birthday: {
					...templateData.birthday,
					value: moment(participantData.birthday),
				},
				height: {
					...templateData.height,
					value: `${participantData.height}`,
				},
				phone: {
					...templateData.phone,
					value: {
						intIdNum: participantData.phone_country_code.name,
						phoneNum: participantData.phone.split(' ')[1],
					},
				},
				email: {
					...templateData.email,
					value: participantData.email,
				},
				emergencyName: {
					...templateData.emergencyName,
					value: participantData.contact_name,
				},
				emergencyRelation: {
					...templateData.emergencyRelation,
					value: {
						label: t(`contactRelationship:${participantData.contact_relationship}`) || '',
						value: participantData.contact_relationship || '',
					},
				},
				emergencyPhone: {
					...templateData.emergencyPhone,
					value: {
						intIdNum: participantData.contact_phone_country_code.name,
						phoneNum: participantData.contact_phone.split(' ')[1],
					},
				},
				eatingHabit: {
					...templateData.eatingHabit,
					value:
						participantData.eating_habit === 'MEAT'
							? {
									value: 'MEAT',
									label: t('eventsReservationForm:meat'),
							  }
							: participantData.eating_habit === 'VEGAN'
							? {
									value: 'VEGAN',
									label: t('eventsReservationForm:vegan'),
							  }
							: {
									value: null,
									label: '',
							  },
				},
				eatingHabitNote: {
					...templateData.eatingHabitNote,
					value: participantData.eating_habit_note || '',
				},
				specialDisease: {
					...templateData.specialDisease,
					value:
						participantData.medical_record === true
							? {
									value: true,
									label: t('eventsReservationForm:yes'),
							  }
							: participantData.medical_record === false
							? {
									value: false,
									label: t('eventsReservationForm:no'),
							  }
							: {
									value: null,
									label: '',
							  },
				},
				specialDiseaseNote: {
					...templateData.specialDiseaseNote,
					value: participantData.medical_record_note || '',
				},
				roommate: { ...templateData.roommate, value: participantData.roommate_name },
				custom1: {
					...templateData.custom1,
					value: participantData.extra_field_1,
				},
				custom2: {
					...templateData.custom2,
					value: participantData.extra_field_2,
				},
				custom3: {
					...templateData.custom3,
					value: participantData.extra_field_3,
				},
				parentConsent: {
					...templateData.parentConsent,
					value: {
						url: participantData.parent_consent,
						filename: participantData.parent_consent?.split('/').pop() || '',
					},
				},
				giveaways: {
					...templateData.giveaways,
					value: giveawaysData,
				},
				productPurchase: {
					...templateData.productPurchase,
					value: productPurchaseData,
				},
			},
		} as SetupParticipantFormByRideOrderPayload;
	}

	return {
		id,
		type: GiantEventRoute.storerides,
		form: {
			...formTemplates.storerides,
			lastName: {
				...templateData.lastName,
				value: participantData.last_name,
			},
			firstName: {
				...templateData.firstName,
				value: participantData.first_name,
			},
			citizenship: {
				...templateData.citizenship,
				value: {
					label: nationalitiesByCode[participantData.nationality]?.name || '',
					value: participantData.nationality || null,
				},
			},
			citizenId: {
				...templateData.citizenId,
				value: participantData.identity_no,
			},
			sex: {
				...templateData.sex,
				value:
					participantData.gender === 'F'
						? { label: t('gender:F'), value: 'F' }
						: { label: t('gender:M'), value: 'M' },
			},
			phone: {
				...templateData.phone,
				value: {
					intIdNum: participantData.phone_country_code.name,
					phoneNum: participantData.phone.split(' ')[1],
				},
			},
			email: {
				...templateData.email,
				value: participantData.email,
			},
			emergencyName: {
				...templateData.emergencyName,
				value: participantData.contact_name,
			},
			emergencyRelation: {
				...templateData.emergencyRelation,
				value: {
					label: t(`contactRelationship:${participantData.contact_relationship}`) || '',
					value: participantData.contact_relationship || '',
				},
			},
			emergencyPhone: {
				...templateData.emergencyPhone,
				value: {
					intIdNum: participantData.contact_phone_country_code.name,
					phoneNum: participantData.contact_phone.split(' ')[1],
				},
			},
			roommate: { ...templateData.roommate, value: participantData.roommate_name },
			custom1: {
				...templateData.custom1,
				value: participantData.extra_field_1,
			},
			custom2: {
				...templateData.custom2,
				value: participantData.extra_field_2,
			},
			custom3: {
				...templateData.custom3,
				value: participantData.extra_field_3,
			},
			giveaways: {
				...templateData.giveaways,
				value: giveawaysData,
			},
			productPurchase: {
				...templateData.productPurchase,
				value: productPurchaseData,
			},
		},
	} as SetupParticipantFormByRideOrderPayload;
});

type SetupParticipantFormByCourseOrderPayload = {
	id: number;
	type: GiantEventRoute.bikeclasses;
	form: EventsParticipantForms['bikeclasses'];
};

/** 將 課程講座 訂單資訊 map 到參加者表單欄位 */
export const setupParticipantFormByCourseOrder = createAction<
	(dispatch: Dispatch, getState: GetState) => SetupParticipantFormByCourseOrderPayload,
	number,
	Extract<EventsParticipantFormType, 'bikeclasses'>
>('SETUP_PARTICIPANT_FORM_BY_COURSE_ORDER', (id, type) => (_: Dispatch, getState: GetState) => {
	const {
		nationalities: {
			data: { byCode: nationalitiesByCode },
		},
		courseOrder: {
			detail: { data: selector },
			bySelector,
		},
		eventsParticipant: {
			form: { template },
		},
		ride: {
			reservationRideInfo: {
				data: { additional_products },
			},
		},
	} = getState();

	const d = bySelector[selector];
	const [participantId] = d.participants.ids.filter(pId => pId === id);
	const participantData = d.participants.byIds[participantId];
	let templateData = template;

	if (templateData === null) {
		templateData = formTemplates.bikeclasses;
	}

	// 當訂單當中有贈品時，將贈品資料帶入表單
	const giveawaysData: ProductSelectedPayload = {};
	const ridesGifts = additional_products?.gifts || [];
	ridesGifts.forEach((product: { stocks: { [x: string]: any }; id: number }) => {
		const stockList = Object.keys(product.stocks).map(key => product.stocks[key]);
		participantData.gift.forEach(gift => {
			if (product.id === gift.product_id) {
				giveawaysData[product.id] = {
					stockId: gift.product_stock_id,
					amount: 0,
					colorId: stockList.find(stock => stock.id === gift.product_stock_id)?.color_id,
					specId: stockList.find(stock => stock.id === gift.product_stock_id)?.size_id,
				};
			}
		});
	});

	// 當訂單當中有加價購商品時，將商品資料帶入表單
	const productPurchaseData: ProductSelectedPayload = {};
	const ridesProducts = additional_products?.products || [];
	ridesProducts.forEach((product: { stocks: { [x: string]: any }; id: number }) => {
		const stockList = Object.keys(product.stocks).map(key => product.stocks[key]);
		participantData.products.forEach(prod => {
			if (product.id === prod.product_id) {
				productPurchaseData[product.id] = {
					stockId: prod.product_stock_id,
					amount: 0,
					colorId: stockList.find(stock => stock.id === prod.product_stock_id)?.color_id,
					specId: stockList.find(stock => stock.id === prod.product_stock_id)?.size_id,
				};
			}
		});
	});

	return {
		id,
		type,
		form: {
			...templateData,
			lastName: {
				...templateData.lastName,
				value: participantData.last_name,
			},
			firstName: {
				...templateData.firstName,
				value: participantData.first_name,
			},
			lastNameEn: {
				...templateData.lastNameEn,
				value: participantData.en_last_name,
			},
			firstNameEn: {
				...templateData.firstNameEn,
				value: participantData.en_first_name,
			},
			citizenship: {
				...templateData.citizenship,
				value: {
					label: nationalitiesByCode[participantData.nationality]?.name || '',
					value: participantData.nationality || null,
				},
			},
			citizenId: {
				...templateData.citizenId,
				value: participantData.identity_no,
			},
			sex: {
				...templateData.sex,
				value:
					participantData.gender === 'F'
						? { label: t('gender:F'), value: 'F' }
						: { label: t('gender:M'), value: 'M' },
			},
			height: {
				...templateData.height,
				value: `${participantData.height}`,
			},
			weight: {
				...templateData.weight,
				value: `${participantData.weight}`,
			},
			phone: {
				...templateData.phone,
				value: {
					intIdNum: participantData.phone_country_code.name,
					phoneNum: participantData.phone.split(' ')[1],
				},
			},
			email: {
				...templateData.email,
				value: participantData.email,
			},
			birthday: {
				...templateData.birthday,
				value: moment(participantData.birthday),
			},
			eatingHabit: {
				...templateData.eatingHabit,
				value:
					participantData.eating_habit === 'MEAT'
						? {
								value: 'MEAT',
								label: t('eventsReservationForm:meat'),
						  }
						: participantData.eating_habit === 'VEGAN'
						? {
								value: 'VEGAN',
								label: t('eventsReservationForm:vegan'),
						  }
						: {
								value: null,
								label: '',
						  },
			},
			eatingHabitNote: {
				...templateData.eatingHabitNote,
				value: participantData.eating_habit_note || '',
			},
			specialDisease: {
				...templateData.specialDisease,
				value:
					participantData.medical_record === true
						? {
								value: true,
								label: t('eventsReservationForm:yes'),
						  }
						: participantData.medical_record === false
						? {
								value: false,
								label: t('eventsReservationForm:no'),
						  }
						: {
								value: null,
								label: '',
						  },
			},
			specialDiseaseNote: {
				...templateData.specialDiseaseNote,
				value: participantData.medical_record_note || '',
			},
			country: {
				...templateData.country,
				value: participantData.country,
			},
			city: {
				...templateData.city,
				value: participantData.city,
			},
			address: {
				...templateData.address,
				value: participantData.address,
			},
			emergencyName: {
				...templateData.emergencyName,
				value: participantData.contact_name,
			},
			emergencyRelation: {
				...templateData.emergencyRelation,
				value: {
					label: t(`contactRelationship:${participantData.contact_relationship}`) || '',
					value: participantData.contact_relationship || '',
				},
			},
			emergencyPhone: {
				...templateData.emergencyPhone,
				value: {
					intIdNum: participantData.contact_phone_country_code.name,
					phoneNum: participantData.contact_phone.split(' ')[1],
				},
			},
			hasParticipated: {
				...templateData.hasParticipated,
				value: participantData.has_participated
					? { value: true, label: t('eventsReservationForm:yes') }
					: { value: false, label: t('eventsReservationForm:no') },
			},
			roommate: {
				...templateData.roommate,
				value: participantData.roommate_name,
			},
			custom1: {
				...templateData.custom1,
				value: participantData.extra_field_1,
			},
			custom2: {
				...templateData.custom2,
				value: participantData.extra_field_2,
			},
			custom3: {
				...templateData.custom3,
				value: participantData.extra_field_3,
			},
			parentConsent: {
				...templateData.parentConsent,
				value: {
					url: participantData.parent_consent,
					filename: participantData.parent_consent?.split('/').pop() || '',
				},
			},
			isPrimary: {
				...templateData.isPrimary,
				value: participantData.is_primary,
			},
			giveaways: {
				...templateData.giveaways,
				value: giveawaysData,
			},
			productPurchase: {
				...templateData.productPurchase,
				value: productPurchaseData,
			},
		},
	} as SetupParticipantFormByCourseOrderPayload;
});

/**
 * 拿回 ride/course data 後依資料更改必填需求
 * participant template -> extra field, apply_type
 */
export const setupEventParticipantTemplateProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>,
	Exclude<GiantEventRouteType, 'travel'>
>(
	'SETUP_EVENT_PARTICIPANT_TEMPLATE_PROCESS',
	type => async (dispatch: Dispatch, getState: GetState) => {
		const {
			ride: { reservationRideInfo },
			course: { reservationCourseInfo },
		} = getState();

		const data = type === 'bikeclasses' ? reservationCourseInfo.data : reservationRideInfo.data;

		// apply_type 報名項目有回傳資料時，設定為必填。
		dispatch(
			changeParticipantFormTemplate({
				key: 'applyType',
				data: {
					required: (data?.additionalsByProductId?.applyTypes || []).length >= 1,
				},
			}),
		);

		// 客製化表單欄位有回傳資料時，設定為必填。
		dispatch(
			changeParticipantFormTemplate({
				key: 'custom1',
				data: {
					required: isExist(data?.extra_field_name_1),
				},
			}),
		);
		dispatch(
			changeParticipantFormTemplate({
				key: 'custom2',
				data: {
					required: isExist(data?.extra_field_name_2),
				},
			}),
		);
		dispatch(
			changeParticipantFormTemplate({
				key: 'custom3',
				data: {
					required: isExist(data?.extra_field_name_3),
				},
			}),
		);

		// 初始化參加者表單
		dispatch(initEventsParticipantForm());
	},
);

export const reducer = {
	eventsParticipant: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_EVENTS_PARTICIPANT: () => ({
				...initialState,
			}),

			SET_EVENTS_PARTICIPANT_TYPE_ID: (state, action: Action<FormTypeIdPayload>) => ({
				...state,
				event: {
					...state.event,
					type: action.payload.eventType,
					...(action.payload.eventId !== undefined && {
						id: action.payload.eventId,
					}),
				},
				form: {
					...state.form,
					template: formTemplates[action.payload.eventType],
				},
			}),

			CHANGE_PARTICIPANT_FORM_TEMPLATE: <T extends EventsParticipantFormType>(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				state: any,
				action: Action<FormChangePayload<T>>,
			) => ({
				...state,
				form: {
					...state.form,
					template: {
						...state.form.template,
						[action.payload.key]: {
							...state.form.template[action.payload.key],
							...action.payload.data,
						},
					},
				},
			}),

			INIT_EVENTS_PARTICIPANT_FORM: state => ({
				...state,
				form: {
					...state.form,
					ids: [],
					byIds: {},
					editing: {
						...state.form.editing,
						id: null,
						data: state.form.template,
					},
				},
			}),

			INIT_EVENTS_EDITING_PARTICIPANT_FORM: state => ({
				...state,
				form: {
					...state.form,
					editing: {
						...state.form.editing,
						data: state.form.template,
					},
				},
			}),

			CHANGE_PARTICIPANT_FORM: <T extends EventsParticipantFormType>(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				state: any,
				action: Action<FormChangePayload<T>>,
			) => ({
				...state,
				form: {
					...state.form,
					editing: {
						...state.form.editing,
						data: {
							...state.form.editing.data,
							[action.payload.key]: {
								...state.form.editing.data[action.payload.key],
								...action.payload.data,
							},
						},
					},
				},
			}),

			CHANGE_PARTICIPANT_ADDITIONALS: (
				state,
				action: Action<ChangeParticipantAdditionalsPayload>,
			) => ({
				...state,
				form: {
					...state.form,
					...(state.form.editing.data !== null && {
						editing: {
							...state.form.editing,

							data: {
								...state.form.editing.data,
								[action.payload.key]: {
									...state.form.editing.data[action.payload.key],

									value: {
										...(action.payload.key === 'applyType'
											? { [action.payload.data.productId]: action.payload.data }
											: {
													...state.form.editing.data[action.payload.key]?.value,
													[action.payload.data.productId]: action.payload.data,
											  }),
									},
								},
							},
						},
					}),
				},
			}),

			REMOVE_PARTICIPANT_FORM: (state, action: Action<FormData['id']>) => ({
				...state,

				form: {
					...state.form,
					ids: state.form.ids.filter(id => id !== action.payload),
				},
			}),

			SET_EVENTS_PARTICIPANT_FORM_EDITABLE: (state, action: Action<number | undefined>) => ({
				...state,
				form: {
					...state.form,
					editing: {
						...state.form.editing,
						id: action.payload === undefined ? null : action.payload,
						data:
							action.payload === undefined ? state.form.template : state.form.byIds[action.payload],
					},
				},
			}),

			COMPLETE_EVENTS_PARTICIPANT_FORM_EDIT: (state, action: Action<number>) => ({
				...state,
				form: {
					...state.form,
					editing: {
						id: null,
						data: null,
					},
					ids:
						state.form.editing.id === null ? [...state.form.ids, action.payload] : state.form.ids,
					byIds: {
						...state.form.byIds,
						[state.form.editing.id === null ? action.payload : state.form.editing.id]: {
							...(state.form.editing.data as FormData<EventsParticipantFormType>['form']),
						},
					},
				},
			}),

			SETUP_PARTICIPANT_FORM_BY_RIDE_ORDER: (
				state: State,
				action: Action<SetupParticipantFormByRideOrderPayload>,
			) => ({
				...state,
				form: {
					...state.form,
					ids: [...state.form.ids, action.payload.id],
					byIds: { ...state.form.byIds, [action.payload.id]: action.payload.form },
				},
			}),

			SETUP_PARTICIPANT_FORM_BY_COURSE_ORDER: (
				state,
				action: Action<SetupParticipantFormByCourseOrderPayload>,
			) => ({
				...state,
				form: {
					...state.form,
					ids: [...state.form.ids, action.payload.id],
					byIds: { ...state.form.byIds, [action.payload.id]: action.payload.form },
				},
			}),

			UPDATE_PARTICIPANT_PRIMARY_STATUS: (
				state,
				action: Action<UpdateParticipantPrimaryStatusPayload>,
			) => ({
				...state,
				form: {
					...state.form,
					byIds: action.payload.data,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useEventsParticipantTypeId ++
++----------------------------------------------------------------------*/
const selectEvent = (state: GlobalState) => state.eventsParticipant.event;
const eventActionsMap = {
	clearEventsParticipant,
	setEventsTypeId,
	initForm: initEventsParticipantForm,
};

export const useEventsParticipantTypeId = () =>
	useRedux<ReturnType<typeof selectEvent>, typeof eventActionsMap>(selectEvent, eventActionsMap);

/* +----------------------------------------------------------------------
	++ useEventsParticipantForm ++
++----------------------------------------------------------------------*/
const selectForm = (state: GlobalState) => state.eventsParticipant.form;
const formActionsMap = {
	clearEventsParticipant,
	changeFormTemplate: changeParticipantFormTemplate,
	removeForm: removeParticipantForm,
	changeForm: changeParticipantForm,
	changeParticipantAdditionals,
	syncEventsParticipantFormByContactInfo,
	setupParticipantFormByRideOrder,
	setupParticipantFormByCourseOrder,
	setParticipantFormEditable,
	completeParticipantFormEdit,
	setupEventParticipantTemplateProcess,
	syncEventsParticipantFormByUserProfile,
	initEventsParticipantForm,
	initEventsEditingParticipantForm,
	setParticipantStatusToPrimary,
	changeParticipantFormError,
};

export const useEventsParticipantForm = () =>
	useRedux<ReturnType<typeof selectForm>, typeof formActionsMap>(selectForm, formActionsMap);
