/* eslint-disable indent */
import i18n from 'i18next';
import qs from 'qs';

import { FetchResponseGiant } from 'types/FetchResponseGiant';
import { FetchResponse } from 'types/FetchResponse';

import { lanList } from 'util/i18n/config';

import session from './session';
import storage from './storage';

const {
	API_ENDPOINT,
	API,
	GIANT_ENDPOINT,
	GIANT_COGNITO_ENDPOINT,
	GIANT_CLIENT_ID,
	X_API_KEY,
} = process.env;

type EndpointType = 'web' | 'baskstage' | 'giant' | 'cognito' | 'external';

interface FetchOthersProperties {
	enpointType: EndpointType;
	withAuth: boolean;
	notThrowError: boolean;
	isFormData: boolean;
	withXAPIKEY: boolean;
	withTravelAuthToken: boolean;
	isAdminTravelToken: boolean;
	isPDF: boolean;
}

const generateUrl = (url: string, params?: object, enpointType?: EndpointType) => {
	const paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

	switch (enpointType) {
		case 'external':
			return `${url}${paramsString !== '' ? `?${paramsString}` : ''}`;

		case 'baskstage':
			return `${API_ENDPOINT}/backstage/api/${url}${paramsString !== '' ? `?${paramsString}` : ''}`;

		// 此處因應 GID 2.0 改版修改此 url param prod => v1
		case 'giant':
			return `${GIANT_ENDPOINT}/v1/${url}${paramsString !== '' ? `?${paramsString}` : ''}`;

		// 此處因應 GID 2.0 更動網址，因此修改
		case 'cognito':
			return `${GIANT_COGNITO_ENDPOINT}oauth2/${url}${
				paramsString !== '' ? `?${paramsString}` : ''
			}`;

		case 'web':
			return `${API_ENDPOINT}/backstage/api/v1/web/${url}${
				paramsString !== '' ? `?${paramsString}` : ''
			}`;

		default:
			return '';
	}
};

export const fetchFunc = async (
	url: string,
	options: RequestInit = { headers: {} },
	params: object = {},
	propsOthers?: Partial<FetchOthersProperties>,
) => {
	const others: FetchOthersProperties = {
		enpointType: 'web',
		withAuth: false,
		notThrowError: false,
		isFormData: false,
		notThrowError: false,
		withXAPIKEY: false,
		withTravelAuthToken: false,
		isAdminTravelToken: false,
		isPDF: false,
		...propsOthers,
	};

	const authToken = {
		web: session.getItem('idToken'),
		baskstage: storage.getItem('token', false),
		giant: session.getItem('accessToken'), // 這邊因應 GID 2.0 改版，修正為給後端帶上 accessToken 做取代
		cognito: null,
		external: null,
	}[others.enpointType];

	// 兩種 travel auth 來源：
	// 1. 使用登入的 GID 或是 使用身份證 + 生日 登入
	// 2. 藉由後台系統登入，會自帶 travel-admin-token
	const travelAuthToken = storage.getItem('travelAuthToken');
	const travelAdminToken = storage.getItem('travel-admin-token', false);

	const URL = generateUrl(url, params, others.enpointType);

	/**
	 * 有關 Fetch API header 的 Content-Type 設定。
	 *
	 * 沒有傳送 FormData，設定 Content-Type 為 'application/json'（適用大部分的 API）。
	 *
	 * 有要傳送 FormData（常見於 Admin API）：
	 * 1. 如果是 GET Request，Content-Type 預設為 'application/x-www-form-urlencoded'， FormData 用 urlencoded 傳送。
	 * 2. 如果是 POST Request，FormData 放進 body 傳送，則瀏覽器會自動將 Content-Type 設為 'multipart/form-data'。
	 *
	 * ref: https://developer.mozilla.org/zh-TW/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest#%E6%8F%90%E4%BA%A4%E8%A1%A8%E5%96%AE%E8%88%87%E4%B8%8A%E5%82%B3%E6%AA%94%E6%A1%88
	 */

	const headers = new Headers({
		...(!others.isFormData && {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}),
		...(others.isPDF && {
			'Content-Type': 'application/pdf',
			Accept: 'application/pdf',
		}),
		...(others.withAuth &&
			authToken && {
				Authorization: `${others.enpointType === 'cognito' ? '' : 'Bearer '}${authToken}`, // ref: Giant API C1-1 refresh_token query https://docs.google.com/spreadsheets/d/1mwERXJPmyN4F-GIxssEUhxgfaSKJNSaiWGWkGI-AEAg/edit#gid=793096493
			}),
		// 因應 GID 2.0 改版，加入心得 X_API_KEY 才能正確地取得 vid (ref: https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/issues/1379#note_1243111)
		...(others.withXAPIKEY &&
			X_API_KEY && {
				'x-api-key': `${X_API_KEY}`,
			}),
		...(others.withTravelAuthToken &&
			travelAuthToken && {
				'travel-token': `${travelAuthToken}`,
			}),
		...(others.isAdminTravelToken &&
			travelAdminToken && {
				'travel-token': `${travelAdminToken}`,
			}),
		...options.headers,
	});

	const response = await fetch(URL, {
		...options,
		headers,
	});

	let result;
	if (others.isPDF) {
		const blob = await response.blob();
		result = { status: response.status, data: blob };
	} else {
		const data = await response.json();
		result = { status: response.status, ...data };
	}

	return result;
};

/**
 * Giant ID auth API C1-1
 * Web, App應用程式透過重整字符（Refresh Token），向 AWS Cognito 的 Endpoint 換取 ID Token 與 Access Token。
 */
export const refreshGiantToken = async () => {
	const { error, message, access_token: newAccessToken, id_token: newIdToken } = await fetchFunc(
		`token`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		},
		{
			grant_type: 'refresh_token',
			client_id: GIANT_CLIENT_ID,
			refresh_token: storage.getItem('refreshToken'),
		},
		{
			enpointType: 'cognito',
		},
	);

	if (error || message) {
		throw new Error(error || message);
	}

	if (newIdToken && newAccessToken) {
		session.setItem('idToken', newIdToken);
		session.setItem('accessToken', newAccessToken);
	}
};

/* +----------------------------------------------------------------------
	++ For v1/web/ endpoint (normal user) ++
++----------------------------------------------------------------------*/
export const wrapFetch = async (
	url: string,
	options: RequestInit = { headers: {} },
	params: object = {},
	others?: {
		isFormData?: boolean;
		withLan?: boolean;
		withLocale?: boolean;
		withAuth?: boolean;
		notThrowError?: boolean; // 不要拋出錯誤，用於特殊情況，例如：驗證身分證重複報名，太早報錯會導致接不到錯誤訊息
		withTravelAuthToken?: boolean; // 旅遊訂單系列都需要使用到該 token
		isAdminTravelToken?: boolean; // 從後台開啟「我要繳費」旅遊訂單詳細頁
		isPDF?: boolean;
	},
) => {
	const setting = {
		isFormData: false,
		withLan: true,
		withLocale: true,
		withAuth: false,
		isPDF: false,
		notThrowError: false,
		isAdminTravelToken: false,
		withTravelAuthToken: false,
		...others,
	};
	const localStorageLng = storage.getItem('i18nextLng');
	const localStorageLocale = storage.getItem('locale');

	// for admin route to get country_id from url query string
	const queryObj = qs.parse(window.location.search, { ignoreQueryPrefix: true });
	const countryId = queryObj.country_id || localStorageLocale || 0;

	// Bring the gid to identify family member.
	const authGid = session.getItem('gid');

	const mergedOptions = {
		...options,
		headers: {
			...options.headers,
			...(authGid && { 'auth-gid': authGid }),
		},
	};

	const response = await fetchFunc(
		url,
		mergedOptions,
		{
			...(setting.withLan && { lang: i18n.language || localStorageLng || lanList[0] }),
			...(setting.withLocale && { country_id: countryId }),
			...params,
		},
		{
			enpointType: 'web',
			withAuth: setting.withAuth,
			notThrowError: setting.notThrowError,
			isPDF: setting.isPDF,
			isFormData: setting.isFormData,
			withTravelAuthToken: setting.withTravelAuthToken,
			isAdminTravelToken: setting.isAdminTravelToken,
		},
	);

	if (response.status !== 200) {
		if (response.status === 401) {
			await refreshGiantToken();

			// retry
			const retryResponse = await fetchFunc(
				url,
				mergedOptions,
				{
					...(setting.withLan && { lang: i18n.language || localStorageLng || lanList[0] }),
					...(setting.withLocale && { country_id: countryId }),
					...params,
				},
				{
					enpointType: 'web',
					withAuth: setting.withAuth,
					isFormData: setting.isFormData,
				},
			);

			return retryResponse;
		}

		// 不要拋出錯誤，用於特殊情況，例如：驗證身分證重複報名，太早報錯會導致接不到錯誤訊息
		if (setting.notThrowError) {
			return response;
		}

		throw new Error(response.message);
	}

	return response;
};

/* +----------------------------------------------------------------------
	++ For backstage endpoint (admin user) ++
++----------------------------------------------------------------------*/
export const wrapRetryAdminFetch: <T>(
	url: string,
	options: RequestInit,
	params?: object,
	others?: { isFormData?: boolean },
) => Promise<FetchResponse<T>> = async (url, options = { headers: {} }, params = {}, others) => {
	try {
		const { status, message, data, error_code } = await fetchFunc(url, options, params, {
			withAuth: true,
			enpointType: 'baskstage',
			...others,
		});

		if (status !== 200 && status !== 201) {
			// 後端有回傳 error_code，就直接拿來使用，之後 error_code 會再經由 i18n 轉換為系統語系
			if (error_code) {
				throw new Error(error_code);
			}

			if (status === 401 || status === false) {
				throw new Error('token expired');
			}

			throw new Error(message);
		}

		return { status, message, data };
	} catch (err) {
		if (err instanceof Error) {
			if (err.message === 'token expired') {
				// refresh token
				const { status: refreshStatus, token } = await fetchFunc(
					`refreshToken`,
					{
						method: 'POST',
					},
					{},
					{
						withAuth: true,
						enpointType: 'baskstage',
					},
				);

				if (refreshStatus !== true) {
					window.location.replace(
						`${
							API === 'dev' ? 'https://giantadventure-dev.25demo.com' : API_ENDPOINT
						}/backstage/en/login?redirect=${window.location.pathname.substring(1)}`,
					);

					throw new Error('refresh token failed');
				}

				storage.setItem('token', token, false);

				try {
					const { status, message, data } = await fetchFunc(url, options, params, {
						withAuth: true,
						enpointType: 'baskstage',
						...others,
					});

					if (status !== 200 && status !== 201) {
						throw new Error(message);
					}

					return { status, message, data };
				} catch (secondErr) {
					if (secondErr instanceof Error) {
						return { status: false, message: secondErr.message };
					}
				}
			}
			return { status: false, message: err.message };
		}
		throw err;
	}
};

/* +----------------------------------------------------------------------
	++ For giant id endpoint ++
++----------------------------------------------------------------------*/
export const wrapRetryGiantFetch: <Data>(
	url: string,
	options: RequestInit,
	params?: object,
	others?: { isFormData?: boolean },
) => Promise<FetchResponseGiant<Data>> = async (
	url,
	options = { headers: {} },
	params = {},
	others,
) => {
	const response = await fetchFunc(url, options, params, {
		withAuth: true,
		enpointType: 'giant',
		...others,
	});

	if (response.status !== 200) {
		if (response.status === 401) {
			await refreshGiantToken();

			// retry
			const retryResponse = await fetchFunc(url, options, params, {
				withAuth: true,
				enpointType: 'giant',
				...others,
			});

			return retryResponse;
		}
		throw new Error(response.message);
	}

	return response;
};
