/* eslint-disable no-mixed-operators */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { AnyAction, Dispatch } from 'redux';
import { ActionsObservable, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import moment from 'moment';

import { GiantEventRoute, GiantEventRouteType } from 'types/GiantEvents';

import { InputFiled } from 'types/InputFiled';
import { FormDataMapping } from 'types/FormDataMapping';
import { FetchedData } from 'types/FetchedData';
import {
	ErrorMessage,
	ErrorMessageCodes,
	errorMessageCodes,
	ERROR_MESSAGE_I18N_NAMESPACE,
	OTHER_ERROR_MESSAGE_I18N_KEY,
} from 'types/ErrorMessage';

import { t } from 'util/i18n';
import { useRedux } from 'util/hook/redux';
import { openPaymentView, createPaymentWindow } from 'util/functions/openPaymentView';
import findDeliveryInterval from 'util/functions/findDeliveryInterval';

import { openModal } from 'models/modal';
import { updateParticipantPrimaryStatus } from 'models/eventsParticipant';
import { getDeliveryWayMappingValue } from 'models/deliveryWay';
import { getInvoiceMappingValue } from 'models/invoice';
import { getPaymentMappingValue } from 'models/payment';

import {
	RideOrderResponse,
	RideOrderTransactionResponse,
	submitRideOrderFunc,
	submitRideOrderTransactionFunc,
} from 'api/rideOrder';
import {
	CourseOrderResponse,
	submitCourseOrderFunc,
	submitCourseOrderTransactionFunc,
} from 'api/courseOrder';
import { DeliveryPriceType } from 'api/ride';

import { BikeclassesFormDataType, FeaturedridesFormDataType } from '../eventsParticipant/type';
import { getCourseLeftQuota } from '../course';
import {
	eventReservationFormDataMapping,
	participantBikeClassesDataMapping,
	participantFeaturedRideDataMapping,
	participantStoreridesDataMapping,
	calcParticipantAdditionalItems,
} from './utils';
import { GetState, State as GlobalState } from '../reducers';
import { EventsFormField, EventsFormFieldData, ShoppingItemData } from './type';

export interface State {
	formStatus: {
		isReady: boolean;

		/**
		 * 是否真正送出訂單（處理訂單中）
		 *
		 * @type {boolean}
		 */
		isSubmitting: boolean;

		/**
		 * 活動課程報名流程
		 *
		 * @type {(0 | 1 | 2 | 3)}
		 */
		step: 0 | 1 | 2 | 3;

		/**
		 * 活動課程訂單在後端 DB 的唯一 ID （用來獲得訂單資訊）
		 *
		 * @type {string}
		 */
		selector: string;
		view: string;
	};
	form: FormDataMapping<EventsFormFieldData>;
	shoppingDetail: {
		totalPrice: number;
		list: ShoppingItemData[];
	};
}

const initialState: State = {
	formStatus: {
		isReady: false,
		isSubmitting: false,
		step: 0,
		selector: '',
		view: '',
	},
	// form: eventReservationFormMockData,
	form: {
		agreeContract: { value: false, valid: true, error: '', required: false },
		isAsMemberInfo: { value: false, valid: true, error: '', required: false },
		asMember: { value: { label: '', value: null }, valid: true, error: '', required: false },
		teamName: { value: '', valid: true, error: '', required: true },
		name: { value: '', valid: true, error: '', required: true },
		cellphone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		phone: { value: { intIdNum: '', phoneNum: '' }, valid: true, error: '', required: true },
		email: { value: '', valid: true, error: '', required: true },
		id: { value: '', valid: true, error: '', required: true },
		gradeSkipCode: { value: '', valid: true, error: '', required: true },
	},
	shoppingDetail: {
		totalPrice: 0,
		list: [],
	},
};

const clearEventsReservation = createAction('CLEAR_EVENTS_RESERVATION');
const initEventsReservationForm = createAction('INIT_EVENTS_RESERVATION_FORM');

const setEventsReservationReady = createAction('SET_EVENTS_RESERVATION_READY');

const setStep = createAction<State['formStatus']['step'], State['formStatus']['step']>(
	'SET_EVENTS_RESERVATION_STEP',
	step => step,
);

const goNextStep = createAction('SET_EVENTS_RESERVATION_NEXT_STEP');

const goPrevStep = createAction('SET_EVENTS_RESERVATION_PREV_STEP');

export interface EventsFormChangePayload {
	key: EventsFormField;
	data: Partial<InputFiled>;
}

export const changeForm = createAction(
	'CHANGE_EVENTS_RESERVATION_FORM',
	({ key, data }: EventsFormChangePayload) => ({
		key,
		data,
	}),
);

/** 同步會員資料到訂單資訊欄位 */
const syncFormWithMemberInfo = createAction<
	(dispatch: Dispatch, getState: GetState) => void,
	boolean
>(
	'SYNC_EVENTS_RESERVATION_FORM_WITH_MEMBER_INFO',
	(check = false) => (dispatch: Dispatch, getState: GetState) => {
		const {
			eventsReservation: { form },
			auth: { currentUserGid, userDataByGid },
		} = getState();

		const memberId = form.asMember.value.value;
		const userData =
			currentUserGid && check
				? userDataByGid[currentUserGid]
				: memberId
				? userDataByGid[memberId]
				: null;

		// todo: 未來可能會需要同步其他的欄位

		/**
		 * 客製化表單規則：
		 *
		 * 因應表單的值需要不同的處理
		 */
		const fieldsToSync = [
			{
				key: 'name',
				value: form.name.value || `${userData?.firstName} ${userData?.lastName}` || '',
			},
			{
				key: 'email',
				value: form.email.value || userData?.email || '',
			},
		];

		fieldsToSync.forEach(field => {
			dispatch(
				changeForm({
					key: field.key as keyof EventsFormFieldData,
					data: {
						value: field.value,
					},
				}),
			);
		});
	},
);

/**
 * 計算活動課程訂單的購物明細和訂單總金額
 *
 * 候補參加者不計算總金額！
 *
 * 訂單總金額 = 正取參加者的（報名費 + 各項加價購） + 物資配送金額
 *
 * 1. 報名費： apply_fee * {正取參加者人數}。
 * 2. 各項加價購：正取參加者的各項加價購 price x {數量}。
 * 		加價購包含 4 種：商品加價購（products）、服務加價購（services）、贈品（gifts）、報名項目（apply_type）。
 * 3. 物資配送金額： delivery_prices 所對應的 price。
 * 		宅配（DeliveryType === HOME_DELIVERY）才有運費。
 */
export const calcEventsReservationShoppingDetail = createAction<
	(_: Dispatch, getState: GetState) => State['shoppingDetail']
>('CALCULATE_EVENTS_RESERVATION_SHOPPING_DETAIL', () => (_: Dispatch, getState: GetState) => {
	const {
		deliveryWay: { mappingValue: deliveryData },
		product: { dataById: productDataById },
		ride: {
			reservationRideInfo: { data: rideInfo }, // 目前是哪一種類型的活動課程
		},
		course: {
			reservationCourseInfo: { data: courseInfo }, // 目前是哪一種類型的活動課程
		},
		eventsParticipant: {
			event: { type: eventType }, // 目前是哪一種類型的活動課程
			form: { byIds: participantById, ids: participantIds },
		},
	} = getState();

	// 活動報名費
	const applyFeePerPerson =
		(eventType === 'bikeclasses'
			? courseInfo?.activity_info.apply_fee
			: rideInfo?.registration_notes?.apply_fee) || 0;

	// 篩選正取參加者
	const primaryParticipantIds = participantIds.filter(
		id => participantById[id].isPrimary?.value ?? true, // 如果 isPrimary 是 undefined（騎乘活動 或 門市約騎），預設所有參加者都是正取。
	);

	/** 計算正取參加者的加價購明細 */
	const primaryAdditionalItems = calcParticipantAdditionalItems(
		primaryParticipantIds,
		participantById,
		productDataById,
	);

	// 篩選備取參加者
	const secondaryParticipantIds = participantIds.filter(id =>
		participantById[id].isPrimary?.value === undefined
			? false // 如果 isPrimary 是 undefined（騎乘活動 或 門市約騎），預設所有參加者都是正取，因此候補名單不會有任何參加者。
			: !participantById[id].isPrimary?.value,
	);

	/** 計算備取參加者的加價購明細 */
	const secondaryAdditionalItems = calcParticipantAdditionalItems(
		secondaryParticipantIds,
		participantById,
		productDataById,
	);

	/** 物資配送價格類型 */

	const deliveryPriceType: DeliveryPriceType | null =
		deliveryData.delivery_type === 'HOME_DELIVERY'
			? findDeliveryInterval(
					participantIds.length,
					(eventType === 'bikeclasses' ? courseInfo : rideInfo)?.delivery_info.delivery_prices ||
						[],
			  )
			: null;

	/** 購物明細 = 所有參加者的（報名項目明細 + 加價購明細） + 宅配明細 */
	const itemlist: ShoppingItemData[] = [
		{
			id: 'applyFee',
			name: t('activity:applyFee'),
			categoryName: '',
			colorName: '',
			specName: '',
			stock: participantIds.length,
			amount: participantIds.length,
			price: applyFeePerPerson * primaryParticipantIds.length, // 只計算正取參加者的報名費
		},
		...primaryAdditionalItems,
		...secondaryAdditionalItems,
		...(deliveryPriceType
			? [
					{
						id: 'delivery',
						name: t('eventsReservationForm:deliveryPrice'),
						categoryName: '',
						colorName: '',
						specName: '',
						stock: 1,
						amount: 1,
						price: deliveryPriceType?.price || 0,
					},
			  ]
			: []),
	];

	/** 總金額 = 正取參加者的（報名費 + 各項加價購） + 物資配送金額 */
	const totalPrice = itemlist.reduce(
		(nowTotalPrice, { price: nextItemPrice }) => nowTotalPrice + nextItemPrice,
		0,
	);

	return {
		totalPrice,
		list: itemlist,
	};
});

type SubmitRideOrderPayload = Partial<FetchedData<RideOrderResponse>>;

/** 送出 主題活動 或 門市約騎 訂單 */
const submitRideOrder = createAction<
	(_: Dispatch, getState: GetState) => Promise<SubmitRideOrderPayload>
>('SUBMIT_RIDE_ORDER', () => async (dispatch: Dispatch, getState: GetState) => {
	/*  map 物資配送資訊 */
	dispatch(getDeliveryWayMappingValue());

	/*  計算訂單總金額 */
	dispatch(calcEventsReservationShoppingDetail());

	const {
		eventsReservation: {
			shoppingDetail: { totalPrice },
		},
	} = getState();

	const isPaymentRequired = totalPrice !== 0;

	if (isPaymentRequired) {
		/*  map 發票資訊 */
		dispatch(getInvoiceMappingValue());
		/*  map 付款方式 */
		dispatch(getPaymentMappingValue());
	}

	const {
		payment: {
			mappingValue: paymentData,
			payMethod: {
				value: { value: payMethod },
			},
		},
		invoice: { mappingValue: invoiceData },
		deliveryWay: { mappingValue: deliveryData },
		ride: {
			reservationRideInfo: { data: rideInfo },
		},
		eventsReservation: { form: formData },
		eventsParticipant: {
			event: { type: eventType },
			form: { byIds: participantById, ids: participantIds },
		},
	} = getState();

	if (rideInfo === null) {
		throw new Error('ride data not found');
	}

	/** map 訂單資訊 */
	const formInfos = eventReservationFormDataMapping(formData);

	/** map 參加者資訊 */
	let particapantsInfo = {};
	switch (eventType) {
		case GiantEventRoute.featuredrides:
			particapantsInfo = participantFeaturedRideDataMapping(
				participantIds,
				participantById as {
					[id: number]: FormDataMapping<FeaturedridesFormDataType>;
				},
			);
			break;
		case GiantEventRoute.storerides:
			particapantsInfo = participantStoreridesDataMapping(
				participantIds,
				participantById as {
					[id: number]: FormDataMapping<BikeclassesFormDataType>;
				},
			);
			break;
		default:
			break;
	}

	const { data, status, message } = await submitRideOrderFunc({
		ride_id: rideInfo.id,
		total_price: totalPrice,
		...formInfos,
		...particapantsInfo,
		...(deliveryData.delivery_type && {
			delivery_type: deliveryData.delivery_type,
			delivery_address: deliveryData.delivery_address,
			delivery_store_id: deliveryData.delivery_store_id || undefined,
		}),
		...(isPaymentRequired && {
			...paymentData,
			...(!rideInfo?.transaction_by_others && invoiceData), // transaction_by_others= true -> 代收代付：隱藏「發票開立」欄位
		}),
	});

	if (status === 200) {
		if ('view' in data && data.view) {
			openPaymentView(window, data.view);
		}

		return Promise.resolve({ data });
	}

	if (message === 'GIFT_REQUIRED' && Array.isArray(data)) {
		dispatch(setStep(0)); // 回到 step 1
		const error = new Error(message);
		(error as any).data = data;
		throw error;
	}

	return { error: message };
});

type SubmitCourseOrderPayload = Partial<FetchedData<CourseOrderResponse>>;

export const recalculateParticipantPrimaryStatusProcess = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<void>
>('RECALCULATE_PARTICIPANT_PRIMARY_STATUS_PROCESS', () => async (dispatch, getState) => {
	const {
		course: {
			reservationCourseInfo: { data: prevCourseInfo },
		},
	} = getState();

	const { leftQuota: prevLeftQuota = 0, dateId = 0 } = prevCourseInfo || {};

	if (dateId) {
		await dispatch(getCourseLeftQuota(dateId));
	}

	const {
		course: {
			reservationCourseInfo: { data: nextCourseInfo },
		},
	} = getState();

	const { leftQuota: nextLeftQuota = 0 } = nextCourseInfo || {};

	if (prevLeftQuota !== nextLeftQuota) {
		dispatch(updateParticipantPrimaryStatus());
		dispatch(calcEventsReservationShoppingDetail());

		throw new Error('NOT_ENOUGH_QUOTA');
	}
});

/** 送出活動課程訂單 */
const submitCourseOrder = createAction<
	(_: Dispatch, getState: GetState) => Promise<SubmitCourseOrderPayload>
>('SUBMIT_COURSE_ORDER', () => async (dispatch: Dispatch, getState: GetState) => {
	/*  map 物資配送資訊 */
	dispatch(getDeliveryWayMappingValue());

	/*  計算訂單總金額 */
	dispatch(calcEventsReservationShoppingDetail());

	const {
		eventsReservation: {
			shoppingDetail: { totalPrice },
		},
	} = getState();

	const isPaymentRequired = totalPrice !== 0;

	if (isPaymentRequired) {
		/*  map 發票資訊 */
		dispatch(getInvoiceMappingValue());
		/*  map 付款方式 */
		dispatch(getPaymentMappingValue());
	}

	const {
		routing: { queries },
		payment: {
			mappingValue: paymentData,
			payMethod: {
				value: { value: payMethod },
			},
		},
		invoice: { mappingValue: invoiceData },
		deliveryWay: { mappingValue: deliveryData },
		course: {
			reservationCourseInfo: { data: courseInfo },
		},
		eventsReservation: { form: formData },
		eventsParticipant: {
			form: { byIds: participantById, ids: participantIds },
		},
	} = getState();

	if (courseInfo === null) {
		throw new Error('course data not found');
	}

	/** map 訂單資訊 */
	const formInfos = eventReservationFormDataMapping(formData);

	/** map 參加者資訊 */
	const particapantsInfo = participantBikeClassesDataMapping(
		participantIds,
		participantById as { [id: number]: FormDataMapping<BikeclassesFormDataType> },
	);

	try {
		const { data, status, message } = await submitCourseOrderFunc({
			course_id: courseInfo.id,
			date: moment(queries.date, 'YYYY/MM/DD').isValid()
				? moment(queries.date, 'YYYY/MM/DD').format('YYYY-MM-DD')
				: '',
			total_price: totalPrice,
			...formInfos,
			...particapantsInfo,
			...(deliveryData.delivery_type && {
				delivery_type: deliveryData.delivery_type,
				delivery_address: deliveryData.delivery_address,
				delivery_store_id: deliveryData.delivery_store_id || undefined,
			}),
			...(isPaymentRequired && {
				...paymentData,
				...(!courseInfo?.transaction_by_others && invoiceData), // transaction_by_others = true -> 代收代付：隱藏「發票開立」欄位
			}),
		});

		if (message === 'GIFT_REQUIRED' && Array.isArray(data)) {
			dispatch(setStep(0)); // 回到 step 1
			const error = new Error(message);
			(error as any).data = data;
			throw error;
		}

		if (status !== 200 && status !== 201) {
			throw new Error(message);
		}

		// let safariWindow: Window | null = null;

		// if (status === 200) {
		// 	if (payMethod === 'atm') safariWindow = createPaymentWindow();
		// 	if (data.view) openPaymentView(safariWindow, data.view);
		// }

		// 此處都改為原地跳轉 issue ref: https://fox.25sprout.com/giant/Giant-Adventure/PM_template/-/issues/1791
		if (status === 200) {
			if (data.view) {
				openPaymentView(window, data.view);
			}

			return Promise.resolve({ data });
		}

		return { data };
	} catch (_error) {
		if (_error instanceof Error) {
			const { message } = _error;
			/* 報名失敗：參加者正取名額 > 目前正取報名餘額；打 API 更新正取報名餘額，並重新計算每一個參加者的正（備）取狀態 */
			if (message === 'NOT_ENOUGH_QUOTA') {
				// await dispatch(recalculateParticipantPrimaryStatusProcess());

				await dispatch(getCourseLeftQuota(courseInfo.dateId));
				dispatch(updateParticipantPrimaryStatus());
				dispatch(calcEventsReservationShoppingDetail());
				dispatch(setStep(0)); // 回到 step 1
			}

			/* 拋出錯誤 SUBMIT_COURSE_ORDER_REJECTED，觸發 catchEventOrderErrorEpic 顯示重新計算正取名額的提示 Modal  */
			throw _error;
		}

		return { error: '' };
	}
});

/**
 * catch submit form error
 */
export const catchEventOrderErrorEpic = (action$: ActionsObservable<AnyAction>) =>
	action$.pipe(
		ofType(
			'SUBMIT_RIDE_ORDER_REJECTED',
			'SUBMIT_COURSE_ORDER_REJECTED',
			'RECALCULATE_PARTICIPANT_PRIMARY_STATUS_PROCESS_REJECTED',
		),
		mergeMap(action => {
			const { message, data } = action.payload;
			const isErrorMessage = errorMessageCodes.includes(message as ErrorMessageCodes);

			if (isErrorMessage && message === ErrorMessage.NOT_ENOUGH_QUOTA) {
				return of(
					openModal({
						category: 'modal',
						type: 'warning',
						i18n: 'eventsReservationForm',
						data: {
							message: 'updateParticipantIsPrimaryStatus',
						},
					}),
				);
			}

			if (message === 'GIFT_REQUIRED' && Array.isArray(data)) {
				const userName = data.join(',');
				return of(
					openModal({
						category: 'toast',
						type: 'message',
						data: {
							message: `${userName} ${t('eventsReservationForm:GIFT_REQUIRED')}`,
							status: 'warning',
						},
					}),
				);
			}

			return of(
				openModal({
					category: 'toast',
					type: 'message',
					i18n: ERROR_MESSAGE_I18N_NAMESPACE,
					data: {
						message: isErrorMessage ? message : OTHER_ERROR_MESSAGE_I18N_KEY,
						status: 'warning',
					},
				}),
			);
		}),
	);

const submitEventsOrderTrnsaction = createAction<
	(_: Dispatch, getState: GetState) => Promise<RideOrderTransactionResponse>,
	GiantEventRouteType,
	string,
	number
>(
	'SUBMIT_EVENTS_ORDER_TRANSACTION',
	(eventType, selector, id) => async (dispatch: Dispatch, getState: GetState) => {
		/** map 發票資訊 */
		dispatch(getInvoiceMappingValue());
		/** map 付款方式 */
		dispatch(getPaymentMappingValue());

		const {
			payment: {
				mappingValue: paymentData,
				payMethod: {
					value: { value: payMethod },
				},
			},
			invoice: { mappingValue: invoiceData },
		} = getState();

		const submitFunc =
			eventType === 'bikeclasses'
				? submitCourseOrderTransactionFunc
				: submitRideOrderTransactionFunc;

		try {
			const { status, message, data } = await submitFunc(selector, id, {
				...paymentData,
				...invoiceData,
			});

			if (status === 200) {
				if (data.view) {
					openPaymentView(window, data.view);
				}

				return Promise.resolve(data);
			}

			throw new Error(message);
		} catch (error) {
			dispatch(
				openModal({
					category: 'toast',
					type: 'message',
					data: {
						message: (error as Error).message,
						status: 'warning',
					},
				}),
			);
			return Promise.reject((error as Error).message);
		}
	},
);

export const reducer = {
	eventsReservation: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			CLEAR_EVENTS_RESERVATION: () => ({
				...initialState,
			}),

			SET_EVENTS_RESERVATION_READY: state => ({
				...state,
				formStatus: {
					...state.formStatus,
					isReady: true,
				},
			}),

			SET_EVENTS_RESERVATION_STEP: (state, action: Action<State['formStatus']['step']>) => ({
				...state,
				formStatus: {
					...state.formStatus,
					step: action.payload,
				},
			}),

			SET_EVENTS_RESERVATION_NEXT_STEP: state => ({
				...state,
				formStatus: {
					...state.formStatus,
					step:
						state.formStatus.step <= 1
							? ((state.formStatus.step + 1) as State['formStatus']['step'])
							: state.formStatus.step,
				},
			}),

			SET_EVENTS_RESERVATION_PREV_STEP: state => ({
				...state,
				formStatus: {
					...state.formStatus,
					step:
						state.formStatus.step >= 1
							? ((state.formStatus.step - 1) as State['formStatus']['step'])
							: state.formStatus.step,
				},
			}),

			CALCULATE_EVENTS_RESERVATION_SHOPPING_DETAIL: (
				state,
				action: Action<State['shoppingDetail']>,
			) => ({
				...state,
				shoppingDetail: action.payload,
			}),

			SUBMIT_RIDE_ORDER_PENDING: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: true,
				},
			}),

			SUBMIT_RIDE_ORDER_FULFILLED: (state, action: Action<SubmitRideOrderPayload>) => ({
				...state,

				...(action.payload.data && {
					formStatus: {
						...state.formStatus,
						isSubmitting: false,
						selector: 'selector' in action.payload.data ? action.payload.data.selector || '' : '',
						view: 'view' in action.payload.data ? action.payload.data.view || '' : '',
					},
				}),
			}),

			SUBMIT_RIDE_ORDER_REJECTED: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
				},
			}),

			SUBMIT_COURSE_ORDER_PENDING: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: true,
				},
			}),

			SUBMIT_COURSE_ORDER_FULFILLED: (state, action: Action<SubmitCourseOrderPayload>) => ({
				...state,

				...(action.payload.data && {
					formStatus: {
						...state.formStatus,
						isSubmitting: false,
						selector: action.payload?.data.selector || '',
						view: action.payload?.data.view || '',
					},
				}),
			}),

			SUBMIT_COURSE_ORDER_REJECTED: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
				},
			}),

			CHANGE_EVENTS_RESERVATION_FORM: (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				state: any,
				action: Action<EventsFormChangePayload>,
			) => ({
				...state,

				form: {
					...state.form,
					[action.payload.key]: {
						...state.form[action.payload.key],
						...action.payload.data,
					},
				},
			}),

			INIT_EVENTS_RESERVATION_FORM: (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				state: any,
			) => ({
				...state,

				form: {
					...initialState.form,
				},
			}),

			SUBMIT_EVENTS_ORDER_TRANSACTION_PENDING: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: true,
				},
			}),

			SUBMIT_EVENTS_ORDER_TRANSACTION_FULFILLED: (
				state,
				action: Action<RideOrderTransactionResponse>,
			) => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
					view: action.payload.view || '',
				},
			}),

			SUBMIT_EVENTS_ORDER_TRANSACTION_REJECTED: state => ({
				...state,

				formStatus: {
					...state.formStatus,
					isSubmitting: false,
				},
			}),
		},
		initialState,
	),
};

/* +----------------------------------------------------------------------
	++ useEventsReservationFormStatus ++
++----------------------------------------------------------------------*/
const selectFormStatus = (state: GlobalState) => state.eventsReservation.formStatus;

const formStatusActionsMap = {
	clearEventsReservation,
	initEventsReservationForm,
	setReady: setEventsReservationReady,
	setStep,
	goNextStep,
	goPrevStep,
	submitRideOrder,
	submitCourseOrder,
	syncFormWithMemberInfo,
	submitEventsOrderTrnsaction,
};

export const useEventsReservationFormStatus = () =>
	useRedux<ReturnType<typeof selectFormStatus>, typeof formStatusActionsMap>(
		selectFormStatus,
		formStatusActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useEventsReservationForm ++
++----------------------------------------------------------------------*/
const selectEventsReservationForm = (state: GlobalState) => state.eventsReservation.form;

const eventsReservationFormActionsMap = {
	changeForm,
	recalculateParticipantPrimaryStatusProcess,
	calcEventsReservationShoppingDetail,
};

export const useEventsReservationForm = () =>
	useRedux<ReturnType<typeof selectEventsReservationForm>, typeof eventsReservationFormActionsMap>(
		selectEventsReservationForm,
		eventsReservationFormActionsMap,
	);

/* +----------------------------------------------------------------------
	++ useEventsReservationShoppingDetail ++
++----------------------------------------------------------------------*/
const selectEventsReservationShoppingDetail = (state: GlobalState) =>
	state.eventsReservation.shoppingDetail;

const eventsReservationShoppingDetailActionsMap = {};

export const useEventsReservationShoppingDetail = () =>
	useRedux<
		ReturnType<typeof selectEventsReservationShoppingDetail>,
		typeof eventsReservationShoppingDetailActionsMap
	>(selectEventsReservationShoppingDetail, eventsReservationShoppingDetailActionsMap);
