export enum ErrorMessage {
	// 活動課程相關錯誤訊息
	THE_GIVEN_DATA_WAS_INVALID = 'THE_GIVEN_DATA_WAS_INVALID',
	'RIDE(COURSE)_NOT_FOUND' = 'RIDE(COURSE)_NOT_FOUND',
	ROOM_NOT_FOUND = 'ROOM_NOT_FOUND',
	OVER_APPLY_QUOTA = 'OVER_APPLY_QUOTA',
	OVER_REGISTRATION_PERIOD = 'OVER_REGISTRATION_PERIOD',
	INVALID_ADDITIONAL_PRODUCT_TYPE = 'INVALID_ADDITIONAL_PRODUCT_TYPE',
	NOT_FOUND = 'NOT_FOUND',
	EXCEED_LIMIT = 'EXCEED_LIMIT',
	STORE_NOT_FOUND = 'STORE_NOT_FOUND',
	VALIDATE_STOCK_ERR = 'VALIDATE_STOCK_ERR',
	GIFT_REQUIRED = 'GIFT_REQUIRED',

	// 對應 Redux Store 內 adminStockBicycle.info.data.status 用來顯示 ModalQrCodeContent 掃描後的車輛庫存狀態
	// ※ 之後看情況可將值新增至 errorMessage s3 資源檔
	INVENTORY_NOT_FOUND = '未開始盤點', // 404
	INVENTORY_BICYCLE_NOT_FOUND = '查無車輛', // 404
	INVENTORY_POSITION_NOT_FOUND = '查無地點', // 404
	INVENTORY_BICYCLE_NOT_ALLOWED = '車輛狀態無法盤點，如：剛新增車輛時狀態為 null', // 400
	INVENTORY_BICYCLE_MAINTAIN_POS_FAILED = '車輛更換保養地點失敗，請檢查有無對應租賃訂單', // 500

	RIDE_NO_FOUND = 'RIDE_NO_FOUND',
	COURSE_NO_FOUND = 'COURSE_NO_FOUND',
	DELIVERY_TYPE_REQUIRED = 'DELIVERY_TYPE_REQUIRED',
	DATE_INVALID = 'DATE_INVALID',
	COURSE_STATUS_ERROR = 'COURSE_STATUS_ERROR',
	REGISTRATION_EXPIRED = 'REGISTRATION_EXPIRED',
	PARTICIPANT_COUNT_ERROR = 'PARTICIPANT_COUNT_ERROR',
	NOT_ALLOW_WAIT = 'NOT_ALLOW_WAIT',
	INVALID_PRODUCT_TYPE = 'INVALID_PRODUCT_TYPE',
	VALIDATE_STOCK_ERROR = 'VALIDATE_STOCK_ERROR',
	VALIDATE_DELIVERY_PRICE_ERROR = 'VALIDATE_DELIVERY_PRICE_ERROR',
	PRICE_ERROR = 'PRICE_ERROR',
	INVOICE_CODE_ERROR = 'INVOICE_CODE_ERROR',
	INVALID_NEWBPAY_TOKEN_ID = 'INVALID_NEWBPAY_TOKEN_ID',
	INVALID_COURSE_DATE_STATUS = 'INVALID_COURSE_DATE_STATUS',
	INVALID_COURSE_DATE_ACTIVE_STATUS = 'INVALID_COURSE_DATE_ACTIVE_STATUS',
	COURSE_DATE_WAITING = 'COURSE_DATE_WAITING',
	NOT_ENOUGH_QUOTA = 'NOT_ENOUGH_QUOTA',
	CODE_NOT_FOUND = 'CODE_NOT_FOUND',
	CODE_USED = 'CODE_USED',
	NOT_ELIGIBLE = 'NOT_ELIGIBLE',
	PARTICIPANT_IDENTITY_DUPLICATED = '參加者身份證／護照號碼重複',

	// 租車相關錯誤訊息
	START_AT_END_AT_INVALID = 'START_AT_END_AT_INVALID',
	START_AT_STORE_CLOSE_DATES_ERROR = 'START_AT_STORE_CLOSE_DATES_ERROR',
	END_AT_STORE_CLOSE_DATES_ERROR = 'END_AT_STORE_CLOSE_DATES_ERROR',
	START_AT_STORE_REGULAR_OFF_DAY_ERROR = 'START_AT_STORE_REGULAR_OFF_DAY_ERROR',
	END_AT_STORE_REGULAR_OFF_DAY_ERROR = 'END_AT_STORE_REGULAR_OFF_DAY_ERROR',
	START_AT_EARLIER_THAN_OPEN_TIME_ERROR = 'START_AT_EARLIER_THAN_OPEN_TIME_ERROR',
	END_AT_EARLIER_THAN_OPEN_TIME_ERROR = 'END_AT_EARLIER_THAN_OPEN_TIME_ERROR',
	START_AT_LATER_THAN_CLOSE_TIME_ERROR = 'START_AT_LATER_THAN_CLOSE_TIME_ERROR',
	END_AT_LATER_THAN_CLOSE_TIME_ERROR = 'END_AT_LATER_THAN_CLOSE_TIME_ERROR',
	START_AT_LATER_THAN_LATEST_RENT_TIME_ERROR = 'START_AT_LATER_THAN_LATEST_RENT_TIME_ERROR',

	// 旅遊相關錯誤
	EXCEEDED_REGISTRATION_DEADLINE = 'EXCEEDED_REGISTRATION_DEADLINE', // 超出報名期限
	ORDERS_MUST_BE_ALL_PRIMARY_OR_ALL_SECONDARY = 'ORDERS_MUST_BE_ALL_PRIMARY_OR_ALL_SECONDARY', // 報名必須全部正取或全部備取
	PRIMARY_NUMBER_HAVE_REACHED_CAPACITY = 'PRIMARY_NUMBER_HAVE_REACHED_CAPACITY', // 正取名額已達上限
	DUPLICATE_REGISTRATION = 'DUPLICATE_REGISTRATION', // 重複報名
	ABROAD_DATA_ERROR = 'ABROAD_DATA_ERROR', // 國外團資料有問題
	PRODUCT_COUNT_ERROR = 'PRODUCT_COUNT_ERROR', // 商品數量有問題
	TOTAL_AMOUNT_ERROR = 'TOTAL_AMOUNT_ERROR', // 總金額錯誤
	NOT_REGISTRATION_STATUS = 'NOT_REGISTRATION_STATUS', // 非可報名狀態
	CAN_NOT_USE_PRIMARY = 'CAN_NOT_USE_PRIMARY', // 不可使用正取名額
	CAN_NOT_USE_CONSOLIDATED = 'CAN_NOT_USE_CONSOLIDATED', // 候補不可使用聯絡人統一付款

	// 國泰付款錯誤訊息
	DATA_FORMAT_ERROR = 'DATA_FORMAT_ERROR', // 資料格式有誤
	MERCHANT_VERIFICATION_DATA_CHECK_FAILED = 'MERCHANT_VERIFICATION_DATA_CHECK_FAILED', // 檢核商家認證資料失敗
	NO_ELIGIBLE_DATA_TO_CANCEL = 'NO_ELIGIBLE_DATA_TO_CANCEL', // 無符合的可取消資料
	MERCHANT_TRANSACTION_URL_ERROR = 'MERCHANT_TRANSACTION_URL_ERROR', // 商家交易網址錯誤
	HOST_BATCH_PROCESSING = 'HOST_BATCH_PROCESSING', // 主機批次執行中
	AUTHORIZATION_CANCELLATION_FAILED = 'AUTHORIZATION_CANCELLATION_FAILED', // 取消授權失敗
	PLEASE_CONTACT_SYSTEM_ADMINISTRATOR = 'PLEASE_CONTACT_SYSTEM_ADMINISTRATOR', // 請洽系統管理員
	SYSTEM_BUSY = 'SYSTEM_BUSY', // 系統忙碌中
	SYSTEM_MAINTENANCE = 'SYSTEM_MAINTENANCE', // 系統維護中
}

export type ErrorMessageCodes = keyof typeof ErrorMessage;

export const ERROR_MESSAGE_I18N_NAMESPACE = 'errorMessage';
export const OTHER_ERROR_MESSAGE_I18N_KEY = 'OTHERS';

export const errorMessageCodes = Object.keys(ErrorMessage) as ErrorMessageCodes[];
